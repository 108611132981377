import firebase from 'firebase/app';
import 'firebase/database';
import lscache from 'lscache';

lscache.flushExpired();

firebase.initializeApp({
  databaseURL: 'https://hacker-news.firebaseio.com'
});
const ref = firebase.database().ref('/v0');

function fetch_(child) {
  return ref
    .child(child)
    .once('value')
    .then(dataSnapshot => dataSnapshot.val());
}

function fetchCache(child, minutes) {
  const cache = lscache.get(child);
  if (cache) {
    return Promise.resolve(cache);
  }
  return fetch_(child)
    .then(data => {
      lscache.set(child, data, minutes);
      return data;
    });
}

function fetchItem(id) {
  return fetchCache(`item/${id}`, 10);
}

function fetchItems(ids) {
  return Promise.all(ids.map(fetchItem));
}

function fetchTopStories() {
  return fetchCache('topstories', 5);
}

export default {
  fetchItem,
  fetchItems,
  fetchTopStories,
};
