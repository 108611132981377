import React from 'react';
import ListItem from './ListItem';
import Comment from './Comment';
import api from './api';

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {children: null};
  }

  componentDidMount() {
    api.fetchItem(this.props.match.params.id).then(item => {
      document.title = `${item.title} | HN`;
      this.setState(item)
      if (!item.kids) {
        return;
      }
      api.fetchItems(item.kids).then(kids => {
        const children = kids.filter(kid => !kid.deleted);
        window.scrollTo(0, 0);
        this.setState({children});
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this.componentDidMount();
    }
  }

  componentWillUnmount() {
    document.title = 'HN';
  }

  render() {
    return (
      <>
        {this.state.id &&
          <>
            <ListItem {...this.state} />
            {!this.state.kids && this.state.type !== 'job' &&
              <code className='meta'>{'// no comments'}</code>
            }
          </>
        }
        <div>
          {this.state.children && this.state.children.map(child =>
            <Comment key={child.id} {...child} />
          )}
        </div>
      </>
    );
  }
}

export default Item;
