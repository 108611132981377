import React from 'react';
import api from './api';
import { timeago } from './ListItem';

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.kids) {
      return
    }
    api.fetchItems(this.props.kids).then(kids => {
      const children = kids.filter(kid => kid && !kid.deleted);
      this.setState({children});
    });
  }

  render() {
    const {props} = this;
    return (
      <div className='comment'>
        <div className='meta'>
          <span>{props.by}</span>{' '}
          {timeago(props.time)} ago
        </div>
        <div dangerouslySetInnerHTML={{__html: props.text}} className='text'></div>
        {this.state.children && this.state.children.map(child =>
          <Comment key={child.id} {...child} />
        )}
      </div>
    );
  }
}

export default Comment;
