import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import List from './List';
import Item from './Item';

class App extends React.Component {
  render() {
    return (
      <Router>
        <>
          <h1><Link to='/'>HN</Link></h1>
          <Route exact path='/:page(\d+)?' component={List} />
          <Route path='/item/:id(\d+)' component={Item} />
        </>
      </Router>
    );
  }
}

export default App;
