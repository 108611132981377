import React from 'react';
import { Link } from "react-router-dom";
import ListItem from './ListItem';
import Subtitle from './Subtitle';
import api from './api';

const maxPage = 5;
const itemsPerPage = 30;

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {items: [], page: props.page};
  }

  componentDidMount() {
    this._fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.key !== prevProps.location.key) {
      this._fetch();
    }
  }

  _fetch() {
    api.fetchTopStories()
      .then(stories => {
        const page = Number(this.props.match.params.page) || 1;
        const begin = (page - 1) * itemsPerPage;
        const end = page * itemsPerPage;
        api.fetchItems(stories.slice(begin, end))
          .then(items => {
            this.setState({items, page});
            window.scrollTo(0, 0);
          });
      }).catch(console.log);
  }

  render() {
    const {items, page} = this.state;
    return (
      <>
        {items.map((item, i) =>
          <ListItem key={i} {...item} />
        )}
        {items.length > 0 && page < maxPage &&
          <div className='more'>
            <Link to={'/' + (page + 1)}>
              More{' '}
              {showSlice(page + 1, itemsPerPage)}
            </Link>
          </div>
        }
        {page > 1 &&
          <Subtitle>
            {' '}{showSlice(page, itemsPerPage)}
          </Subtitle>
        }
      </>
    );
  };
};

export default List;

function showSlice(page, itemsPerPage) {
  const begin = (page - 1) * itemsPerPage;
  const end = page * itemsPerPage;
  return `[${begin}:${end}]`;
}
