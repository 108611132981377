import React from 'react';
import { Link } from "react-router-dom";

function ListItem(props) {
  const itemPath = `/item/${props.id}`;
  return (
    <div className='item'>
      <div>
        <a href={props.url || itemPath}>{props.title}</a>
        {props.url &&
          <span className='host'> ({getHost(props.url)})</span>
        }
      </div>
      <div>
        <Link to={itemPath} className='meta'>
          {timeago(props.time)} ago {' '}
          by {props.by} {' '}
          {props.score} points{' '}
          {showComments(props.descendants)}
        </Link>
      </div>
    </div>
  );
}

export default ListItem;

export function timeago(time) {
  const seconds = Date.now() / 1000 - Number(time);
  if (seconds < 3600) {
    return Math.floor(seconds / 60) + 'm';
  } else if (seconds < 86400) {
    return Math.floor(seconds / 3600) + 'h';
  } else {
    return Math.floor(seconds / 86400) + 'd';
  }
}

function showComments(n) {
  if (n) {
    return n === 1 ? `${n} comment` : `${n} comments`;
  }
  return '';
}

function getHost(url) {
  return url.split('/')[2].replace(/^(www|blog)\./, '');
}
